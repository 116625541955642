import React, {useMemo} from "react";
import {
    CssBaseline,
    ThemeProvider as MuiThemeProvider,
} from "@mui/material";
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import "./App.css";

import {createMuiTheme} from "./Theme";
import * as locales from "@mui/material/locale";
import {useIntl} from "react-intl";
import Banner from "./components/banner.component";
import Root from "./routes/root";
import Verification from "./routes/verification";
import ResetPassword from "./routes/resetpassword";

function App() {
    const intl = useIntl();

    const lang = useMemo(() => {
        switch (intl.locale) {
            case "fr":
                return locales["frFR"];
            case "en":
                return locales["enUS"];
            case "de":
                return locales["deDE"];
        }
    }, [intl]);

    const muiTheme = useMemo(
        () => createMuiTheme("light", lang ?? locales["frFR"]),
        [lang]
    );

    const router = createBrowserRouter([
        {
            path: "/",
            element: <Root/>,
        },
        {
            path: "verification",
            element: <Verification/>,
        },
        {
            path: "reset/password",
            element: <ResetPassword/>,
        },
    ], {
        basename: process.env.REACT_APP_ROUTER_PREFIX
    });

    return (
        <div className="App">
            <MuiThemeProvider theme={muiTheme}>
                <CssBaseline enableColorScheme/>

                <Banner/>
                <main className="main">
                    <RouterProvider router={router} />
                </main>
            </MuiThemeProvider>
        </div>
    );
}

export default App;
