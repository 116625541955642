import "./banner.style.css";

import { ReactComponent as LogoBanner } from "../assets/logo_banner.svg";
import LangSelect from "./langselect.component";

function Banner() {
  return (
      <header className="header">
          <div className="header__logo">
              <LogoBanner/>
          </div>
          <div className="header__lang">
              <LangSelect/>
          </div>
          <div className="header__user"></div>
      </header>
  );
}

export default Banner;
