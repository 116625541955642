import {useEffect} from "react";

function Root() {

    useEffect(() => {
        window.location.replace('https://www.bystamp.com/application-keymo')
    });

    return (
        <></>
    );
}

export default Root;