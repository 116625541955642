import {Card, CircularProgress, Stack} from "@mui/material";
import useFormatters from "../hooks/use-formatters";
import {useEffect, useState} from "react";
import {decodeJwt, JWTPayload} from "jose";

import {BystampApi} from "../network/bystamp-api";

import {ReactComponent as SuccessSVG} from "../assets/Success.svg";
import {ReactComponent as ErrorSVG} from "../assets/Error.svg";
import BSButton from "../components/button.component";

enum VerificationState {
    LOADING,
    SUCCESS,
    EXPIRED,
    RESENT,
    ERROR
}

function Verification() {
    const {formatMessage} = useFormatters();

    const api = new BystampApi();

    const [state, setState] = useState<VerificationState>(VerificationState.LOADING);
    const [token, setToken] = useState<string | null>(new URLSearchParams(window.location.search).get("code"));
    const [decodedToken, setDecodedToken] = useState<JWTPayload | null>();

    useEffect(() => {
        // Delay is to avoid the double request made by the React StrictMode remount in dev env.. it's ugly but it's the best implementation I could find.
        const requestDelay = setTimeout(() => {
            if (!token) setState(VerificationState.ERROR);
            else {
                const decodedJwt = decodeJwt(token);
                setDecodedToken(decodedJwt);

                if (decodedJwt.exp && decodedJwt.exp * 1000 < Date.now()) {
                    setState(VerificationState.EXPIRED);
                    return;
                }

                api.validateAccount(token).then(res => {
                    if (res.ok) {
                        setState(VerificationState.SUCCESS);
                    } else {
                        setState(VerificationState.ERROR);
                    }
                }).catch(err => {
                    console.error(err);
                    setState(VerificationState.ERROR);
                })
            }
        }, 100);

        return () => clearTimeout(requestDelay);
    }, []);

    function resendLink(sub: string) {
        api.resendValidationLink(parseInt(sub)).then(res => {
            if (res.ok) {
                setState(VerificationState.RESENT);
            } else {
                setState(VerificationState.ERROR);
            }
        }).catch(err => {
            console.error(err);
            setState(VerificationState.ERROR);
        });
    }

    return (
        <Stack
            direction="column"
            spacing={2}
            alignItems={"center"}
            marginTop={3}
            width={"100%"}
        >
            <Card className="card">
                {state === VerificationState.LOADING ? <CircularProgress/> :
                    (state === VerificationState.SUCCESS ?
                            <Stack
                                direction="column"
                                spacing={2}
                                alignItems={"center"}
                                paddingTop={1}
                                paddingBottom={2}
                            >
                                <SuccessSVG/>

                                <h1 className="title">
                                    {formatMessage("verification.account.activated.title")}
                                </h1>

                                <p className="text-body">
                                    {formatMessage("verification.account.activated.desc")}
                                </p>
                            </Stack> : state === VerificationState.EXPIRED ?
                                <Stack
                                    direction="column"
                                    spacing={2}
                                    alignItems={"center"}
                                    paddingTop={1}
                                    paddingBottom={2}
                                >
                                    <ErrorSVG/>

                                    <h1 className="title">
                                        {formatMessage("error.expired.title")}
                                    </h1>

                                    <p className="text-body">
                                        {formatMessage("error.expired.verification.account")}
                                    </p>

                                    <BSButton
                                        onClick={() => {
                                            if (decodedToken && decodedToken.sub) {
                                                resendLink(decodedToken.sub);
                                            }
                                        }}
                                    >
                                        {formatMessage("error.expired.verification.account.button")}
                                    </BSButton>
                                </Stack> : state === VerificationState.RESENT ?
                                    <Stack
                                        direction="column"
                                        spacing={2}
                                        alignItems={"center"}
                                        paddingTop={1}
                                        paddingBottom={2}
                                    >
                                        <SuccessSVG/>

                                        <h1 className="title">
                                            {formatMessage("verification.account.resent.title")}
                                        </h1>

                                        <p className="text-body">
                                            {formatMessage("verification.account.resent.desc")}
                                        </p>

                                        <p className="text-body">
                                            {formatMessage("close.tab")}
                                        </p>
                                    </Stack> :
                                    <Stack
                                        direction="column"
                                        spacing={2}
                                        alignItems={"center"}
                                        paddingTop={1}
                                        paddingBottom={2}
                                    >
                                        <ErrorSVG/>

                                        <h1 className="title">
                                            {formatMessage("verification.account.error.title")}
                                        </h1>

                                        <p className="text-body">
                                            {formatMessage("verification.account.error.desc")}
                                        </p>

                                        <p className="text-body">
                                            {formatMessage("error.support.text")} <a
                                            href="https://bystamp.crisp.help">{formatMessage("error.support.link")}</a>
                                        </p>
                                    </Stack>
                    )
                }
            </Card>
        </Stack>
    );
}

export default Verification;