import React, {
    createContext,
    useContext,
    useEffect,
    useState,
    FunctionComponent,
} from "react";
import { IntlProvider } from "react-intl";
import fr from "../translations/fr.json";
import en from "../translations/en.json";
import de from "../translations/de.json";
import ja from "../translations/ja.json";
import es from "../translations/es.json";
import { ReactComponent as FRSvg } from "flag-icons/flags/4x3/fr.svg"
import { ReactComponent as GBSvg } from "flag-icons/flags/4x3/gb.svg"
import { ReactComponent as DESvg } from "flag-icons/flags/4x3/de.svg"
import { ReactComponent as JASvg } from "flag-icons/flags/4x3/jp.svg"
import { ReactComponent as ESSvg } from "flag-icons/flags/4x3/es.svg"
import {StorageContext} from "./storage.context";

export interface BsLocale {
    value: string;
    Flag: React.FC<React.SVGProps<SVGSVGElement>>;
    label: Translation;
    messages: Translations;
}
export const LOCALES: BsLocale[] = [
    { value: "en", Flag: GBSvg, label: 'language.en', messages: en },
    { value: "fr", Flag: FRSvg, label: 'language.fr', messages: fr },
    { value: "de", Flag: DESvg, label: 'language.de', messages: de },
    { value: "es", Flag: ESSvg, label: 'language.es', messages: es },
    { value: "ja", Flag: JASvg, label: 'language.ja', messages: ja },
];

const getLocale = (loc: string) => {
    return LOCALES.find(({ value: locale }) => locale === loc) || LOCALES[0];
};

export const IntlContext = createContext<ByStampSign.IntlContext>(
    {} as ByStampSign.IntlContext
);

const IntlContextProvider: FunctionComponent<{children: React.ReactNode}> = ({ children }) => {
    const storage = useContext(StorageContext);

    const localeSetting = storage.locale;
    const initialLocale: BsLocale = getLocale(localeSetting);
    const [locale, setLocale] = useState(initialLocale);

    useEffect(() => {
        storage.setLocale(locale.value);
    }, [locale, storage]);

    return (
        <IntlContext.Provider
            value={{
                setLocale: (locale: string) => {
                    setLocale(getLocale(locale));
                },
            }}
        >
            <IntlProvider locale={locale.value} messages={locale.messages}>
                {children}
            </IntlProvider>
        </IntlContext.Provider>
    );
};

export default IntlContextProvider;
