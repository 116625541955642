import {Agent, HttpResponse} from "./agent";

const baseUrl = process.env.REACT_APP_BASEURL;

export const backendVersion = "/v1";

export class BystampApi {
    private agent = new Agent();

    private authRequestMapping = "/auth";

    async validateAccount(
        token: string
    ): Promise<HttpResponse<string>> {
        return this.agent.put(
            `${baseUrl}${backendVersion}${this.authRequestMapping}/validate`,
            {},
            token,
            true
        );
    }

    async resendValidationLink(
        id: number
    ): Promise<HttpResponse<string>> {
        return this.agent.post(
            `${baseUrl}${backendVersion}${this.authRequestMapping}/validate/resend`,
            {user_id: id},
            undefined,
            true
        );
    }

    async resetPassword(
        token: string,
        password: string
    ): Promise<HttpResponse<string>> {
        return this.agent.put(
            `${baseUrl}${backendVersion}/users/password`,
            {password: password},
            token,
            true
        );
    }
}