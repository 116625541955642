import {
    Card,
    CircularProgress,
    FormControl,
    FormHelperText, IconButton, InputAdornment,
    InputLabel,
    OutlinedInput,
    Stack,
    useTheme
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import useFormatters from "../hooks/use-formatters";
import {ChangeEvent, useEffect, useState} from "react";
import BSButton from "../components/button.component";

import {BystampApi} from "../network/bystamp-api";
import {sha512} from "../utils/encode-utils";

import {ReactComponent as SuccessSVG} from "../assets/Success.svg";
import {ReactComponent as ErrorSVG} from "../assets/Error.svg";
import {decodeJwt, JWTPayload} from "jose";

enum ResetPasswordState {
    FORM,
    LOADING,
    SUCCESS,
    EXPIRED,
    ERROR
}

function ResetPassword() {
    const {formatMessage} = useFormatters();
    const {colors} = useTheme();

    const api = new BystampApi();

    const [state, setState] = useState<ResetPasswordState>(ResetPasswordState.FORM);
    const [token, setToken] = useState<string | null>(new URLSearchParams(window.location.search).get("code"));
    const [decodedToken, setDecodedToken] = useState<JWTPayload | null>();

    const [values, setValues] = useState<{ password: string, confirm: string }>({
        password: "",
        confirm: "",
    });
    const [showPassword, setShowPassword] = useState(false);
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

    useEffect(() => {
        if (!token) {
            setState(ResetPasswordState.ERROR);
        } else {
            const decodedJwt = decodeJwt(token);
            setDecodedToken(decodedJwt);

            if (decodedJwt.exp && decodedJwt.exp * 1000 < Date.now()) {
                setState(ResetPasswordState.EXPIRED);
                return;
            }
        }
    }, []);

    useEffect(() => {
        // Update the button's disabled status based on the latest `values`
        const isPasswordValid = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_]).{8,}$/.test(values.password);
        const isConfirmMatching = values.password === values.confirm;

        setIsSubmitDisabled(values.password === "" || !isPasswordValid || !isConfirmMatching);
    }, [values]);

    const handleChange =
        (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, prop: keyof {
            password: string,
            confirm: string
        }) => {
            setValues({...values, [prop]: event.target.value});
            console.log(values)
        };

    const submit = async () => {
        if (token && values.password) {
            setState(ResetPasswordState.LOADING);

            api.resetPassword(token, await sha512(values.password)).then(res => {
                if (res.ok) {
                    setState(ResetPasswordState.SUCCESS);
                } else {
                    setState(ResetPasswordState.ERROR);
                }
            }).catch(err => {
                console.error(err);
                setState(ResetPasswordState.ERROR);
            });
        }
    }

    return (
        <Stack
            direction="column"
            spacing={2}
            alignItems={"center"}
            marginTop={3}
            width={"100%"}
        >
            <Card className="card">
                {state === ResetPasswordState.FORM ?
                    <Stack
                        direction="column"
                        spacing={2}
                        alignItems={"center"}
                        paddingTop={1}
                        paddingBottom={2}
                    >
                        <h1 className="title">
                            {formatMessage("reset.password.title")}
                        </h1>

                        <p className="text-body" style={{marginBottom: "20px"}}>
                            {formatMessage("reset.password.desc")}
                        </p>

                        <FormControl sx={{m: 1, width: "75%"}} required={true}>
                            <InputLabel style={{color: colors.darkGrey2}}>
                                {formatMessage("form.password")}
                            </InputLabel>
                            <OutlinedInput
                                name="password"
                                type={showPassword ? "text" : "password"}
                                autoFocus
                                label={formatMessage("form.password")}
                                value={values.password}
                                onChange={(e) => handleChange(e, "password")}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            style={{
                                                color: colors.darkGrey2
                                            }}
                                            aria-label="toggle confirm visibility"
                                            onClick={() => setShowPassword(!showPassword)}
                                            edge="end"
                                        >
                                            {showPassword ? (
                                                <VisibilityIcon />
                                            ) : (
                                                <VisibilityOffIcon />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                            <FormHelperText>{formatMessage("form.help.password")}</FormHelperText>
                        </FormControl>
                        <FormControl sx={{m: 1, pb: 2, width: "75%"}} required={true}>
                            <InputLabel style={{color: colors.darkGrey2}}>
                                {formatMessage("form.confirm")}
                            </InputLabel>
                            <OutlinedInput
                                name="confirm"
                                type={showPassword ? "text" : "password"}
                                label={formatMessage("form.confirm")}
                                value={values.confirm}
                                onChange={(e) => handleChange(e, "confirm")}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            style={{
                                                color: colors.darkGrey2
                                            }}
                                            aria-label="toggle confirm visibility"
                                            onClick={() => setShowPassword(!showPassword)}
                                            edge="end"
                                        >
                                            {showPassword ? (
                                                <VisibilityIcon />
                                            ) : (
                                                <VisibilityOffIcon />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                            {values.confirm && values.password !== values.confirm && (<FormHelperText error>{formatMessage("form.error.confirm")}</FormHelperText>)}
                        </FormControl>
                        <BSButton
                            disabled={isSubmitDisabled}
                            onClick={() => submit()}
                        >
                            {formatMessage("form.send")}
                        </BSButton>
                    </Stack>
                    :
                    (state === ResetPasswordState.LOADING ? <CircularProgress/> :
                            (state === ResetPasswordState.SUCCESS ?
                                    <Stack
                                        direction="column"
                                        spacing={2}
                                        alignItems={"center"}
                                        paddingTop={1}
                                        paddingBottom={2}
                                    >
                                        <SuccessSVG/>

                                        <h1 className="title">
                                            {formatMessage("reset.password.done.title")}
                                        </h1>

                                        <p className="text-body">
                                            {formatMessage("reset.password.done.desc")}
                                        </p>
                                    </Stack> : state === ResetPasswordState.EXPIRED ?
                                        <Stack
                                            direction="column"
                                            spacing={2}
                                            alignItems={"center"}
                                            paddingTop={1}
                                            paddingBottom={2}
                                        >
                                            <ErrorSVG/>

                                            <h1 className="title">
                                                {formatMessage("error.expired.title")}
                                            </h1>

                                            <p className="text-body">
                                                {formatMessage("error.expired.reset.password")}
                                            </p>

                                            <p className="text-body">
                                                {formatMessage("error.expired.reset.password.redo")}
                                            </p>

                                            <p className="text-body">
                                                {formatMessage("close.tab")}
                                            </p>
                                        </Stack> :
                                        <Stack
                                            direction="column"
                                            spacing={2}
                                            alignItems={"center"}
                                            paddingTop={1}
                                            paddingBottom={2}
                                        >
                                            <ErrorSVG/>

                                            <h1 className="title">
                                                {formatMessage("reset.password.error.title")}
                                            </h1>

                                            <p className="text-body">
                                                {formatMessage("reset.password.error.desc")}
                                            </p>

                                            <p className="text-body">
                                                {formatMessage("error.support.text")} <a
                                                href="https://bystamp.crisp.help">{formatMessage("error.support.link")}</a>
                                            </p>
                                        </Stack>
                            )
                    )
                }
            </Card>
        </Stack>
    );
}

export default ResetPassword;