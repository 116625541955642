import { BSException } from "./exception.model";
import { decodeJwt } from "jose";
import { backendVersion } from "./bystamp-api";

enum Audience {
    API_KEY = "API_KEY",
    USER = "USER",
}

export interface HttpResponse<T> extends Response {
    parsedBody?: T & { error_message?: string };
}

const baseUrl = process.env.REACT_APP_BASEURL;

export class Agent {

    private static async http<T>(
        request: Request,
        voidResponse = false
    ): Promise<HttpResponse<T>> {

        // Check token validity before requesting
        // if (request.headers.has("Authorization")) {
        //     const token = request.headers.get("Authorization");
        //
        //     if (!token) {
        //         throw new BSException("desktop.token.undefined", "Token is undefined", 401);
        //     }
        //
        //     const decodedToken = decodeJwt(token);
        //
        //     if (!Object.values(Audience).includes(decodedToken.aud as Audience)) {
        //         throw new BSException("desktop.unexpected.token.aud", `Unexpected token audience "${decodedToken.aud}"`, 401);
        //     }
        //
        //     // Check if token is expired
        //     if (decodedToken.exp && decodedToken.exp * 1000 < Date.now()) {
        //         // Refresh token
        //         const refreshEndpoint = (decodedToken.aud === Audience.API_KEY) ? `${baseUrl}${backendVersion}/auth/providers/refresh` : `${baseUrl}${backendVersion}/auth/refresh`;
        //         const refreshToken = (decodedToken.aud === Audience.API_KEY) ? process.env.APP_REFRESH_TOKEN : localStorage.getItem("userRefreshToken");
        //
        //         if (!refreshToken) {
        //             throw new BSException("desktop.refresh.token.undefined", `${decodedToken.aud} Refresh token is undefined`, 401);
        //         }
        //
        //         const newTokenResponse: HttpResponse<{ access_token: string }> = await fetch(refreshEndpoint, {
        //             method: "post",
        //             headers: {
        //                 "Content-Type": "application/json"
        //             },
        //             body: JSON.stringify({ token: refreshToken })
        //         });
        //
        //         if (!newTokenResponse.ok) {
        //             throw new BSException("desktop.refresh.token.failed", `${decodedToken.aud} Token refresh failed`, newTokenResponse.status);
        //         }
        //
        //         newTokenResponse.parsedBody = await newTokenResponse.json();
        //
        //         if (!newTokenResponse.parsedBody?.access_token) {
        //             throw new BSException("desktop.access.token.notfound", `${decodedToken.aud} Access token not found in response`, 401);
        //         }
        //
        //         // replace old token with new token
        //         request.headers.set("Authorization", newTokenResponse.parsedBody?.access_token);
        //
        //         if (decodedToken.aud === Audience.API_KEY) {
        //             localStorage.setItem("appAccessToken", newTokenResponse.parsedBody.access_token);
        //         }
        //     }
        // }

        let response: HttpResponse<T>;

        try {
            response = await fetch(request) as HttpResponse<T>;
        } catch (error) {
            if (error instanceof Error) {
                throw new BSException("desktop.network.error", `Network error: ${error.message}`);
            } else {
                throw new BSException("desktop.unknown.network.error", "An unknown network error occurred");
            }
        }

        if (!response.ok) {
            let errorBody: T & { error?: string, error_message?: string } | undefined;
            try {
                errorBody = await response.json();
            } catch {
                errorBody = { error_message: response.statusText } as T & { error_message?: string };
            }
            throw new BSException(errorBody?.error || "desktop.http.error", errorBody?.error_message || response.statusText, response.status);
        }

        if (!voidResponse) {
            try {
                response.parsedBody = await response.json();
            } catch (error) {
                if (error instanceof Error) {
                    throw new BSException("desktop.json.parse", `JSON parse error: ${error.message}`);
                } else {
                    throw new BSException("desktop.unknown.json.parse", "An unknown JSON parse error occurred");
                }
            }
        }

        return response;
    }

    async get<T>(
        path: string,
        token?: string,
        voidResponse?: boolean,
        args: RequestInit = {
            method: "get",
            headers: {}
        }
    ): Promise<HttpResponse<T>> {
        const req = new Request(path, args);
        if (token) {
            req.headers.set("Authorization", `${token}`);
        }
        return await Agent.http<T>(req, voidResponse);
    }

    async post<T>(
        path: string,
        body: any,
        token?: string,
        voidResponse?: boolean,
        args: RequestInit = {
            method: "post",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(body)
        }
    ): Promise<HttpResponse<T>> {
        const req = new Request(path, args);
        if (token) {
            req.headers.set("Authorization", `${token}`);
        }
        return await Agent.http<T>(req, voidResponse);
    }

    async put<T>(
        path: string,
        body: any,
        token?: string,
        voidResponse?: boolean,
        args: RequestInit = {
            method: "put",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(body)
        }
    ): Promise<HttpResponse<T>> {
        const req = new Request(path, args);
        if (token) {
            req.headers.set("Authorization", `${token}`);
        }
        return await Agent.http<T>(req, voidResponse);
    }

    async delete(
        path: string,
        token?: string,
        voidResponse?: boolean,
        args: RequestInit = {
            method: "delete",
            headers: { "Content-Type": "application/json" }
        }
    ): Promise<HttpResponse<string>> {
        const req = new Request(path, args);
        if (token) {
            req.headers.set("Authorization", `${token}`);
        }
        return await Agent.http(req, voidResponse);
    }
}
